import React, { useEffect, useState } from 'react'

const Progress = () => {
    const [scrollPercentage, setScrollPercentage] = useState({
        scroll: 0,
    })

    const { scroll } = scrollPercentage

    useEffect(() => {
        window.addEventListener('scroll', progressBar)
    }, [])

    const progressBar = () => {
        const scrollTotal = document.documentElement.scrollTop
        const heightWin =
            document.documentElement.scrollHeight - document.documentElement.clientHeight - 800
        const calculatedScroll = `${(scrollTotal / heightWin) * 100}%`

        setScrollPercentage({
            scroll: calculatedScroll,
        })
    }

    const progressMainWrapper = {
        background: 'rgba(255, 255, 255, 0.14)',
        height: '4px',
        position: 'fixed',
        top: 60,
        left: 0,
        zIndex: 101,
        width: '100%',
    }

    const progressMainStyle = {
        height: '4px',
        background: 'var(--lenses-red)',
        width: scroll,
    }

    return (
        <div className='progress-bar' style={progressMainWrapper}>
            <div style={progressMainStyle} />
        </div>
    )
}

export default Progress
