export function pricingContactBlogsSscript() {
    if (typeof window === 'undefined') return;
    // @ts-ignore
    if (typeof window.signals !== 'undefined') return;
    var script = document.createElement('script');
    script.src = 'https://cdn.cr-relay.com/v1/site/1f878624-8842-46c2-b5c5-5aa116d51456/signals.js';
    script.async = true;
    // @ts-ignore
    window.signals = Object.assign(
        [],
        ['page', 'identify', 'form'].reduce(function (acc, method){
            acc[method] = function () {
                // @ts-ignore
                signals.push([method, arguments]);
                // @ts-ignore
                return signals;
            };
            return acc;
        }, {})
    );
    document.head.appendChild(script);
}